import { Box, Container, Flex, HStack, Text } from '@chakra-ui/react';
import config from 'config';
import { motion } from 'framer-motion';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Distributor, SystemPartner } from '../api/types';
import OeLogo from '../components/OeLogo';
import { Environments, isTestingEnvironment } from '../helpers/environments';
import { DebuggingBanner } from './DebuggingBanner';

type PublicPageLayoutProps = {
  children?: React.ReactNode;
  showOELogo?: boolean;
  systemPartner?: SystemPartner | null | undefined;
  distributor?: Distributor | null | undefined;
  showCobrandedSubheadline?: boolean;
  showCobrandedLogo?: boolean;
  backgroundColor?: string;
  abTestNewDesign?: boolean;
  currentStep?: number;
  overWriteDesign?: boolean;
};

export const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({
  showOELogo = true,
  systemPartner,
  distributor,
  showCobrandedSubheadline,
  showCobrandedLogo,
  children,
  backgroundColor,
  abTestNewDesign,
  overWriteDesign,
}) => {
  const { t } = useTranslation();

  const distributorName = distributor?.name;
  const svgUrl = distributor?.logo_url;
  const spfLogo = systemPartner?.logo_url;

  return (
    <Box bgColor={abTestNewDesign ? 'gray.200' : undefined}>
      {isTestingEnvironment() &&
      config.appEnv !== Environments.STAGE &&
      config.appEnv !== Environments.QA ? (
        <DebuggingBanner />
      ) : null}
      {abTestNewDesign || overWriteDesign ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {(abTestNewDesign || overWriteDesign) && (
            <Box zIndex="sticky" position="sticky" top={0} margin={'0'}>
              <Flex
                pl={9}
                bgColor={'blue.500'}
                style={{ height: 40 }}
                fontSize={'14'}
                align={'center'}
              >
                <Text color={'white'}>{t('abtests:registration_page.slogan.label')}</Text>
              </Flex>
              {showOELogo &&
                !showCobrandedLogo &&
                (abTestNewDesign || overWriteDesign) && (
                  <HStack
                    justifyContent={'left'}
                    mb={-10}
                    pl={'7'}
                    bgColor={'white'}
                    height={'90'}
                  >
                    <OeLogo width={180} height={40} />
                    {spfLogo && (
                      <Flex
                        flexDir={'column'}
                        pl={10}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Text as="b">
                          {t('pages:pre_paid_welcome_page.in_collaboration_with')}
                        </Text>
                        <img
                          src={spfLogo}
                          alt={`${distributorName}` + ' Logo'}
                          width={180}
                          height={40}
                          marginInline={'auto'}
                          marginBlockEnd={{ base: 8, lg: 16 }}
                        />
                      </Flex>
                    )}
                  </HStack>
                )}
            </Box>
          )}
          {(!abTestNewDesign && showOELogo && !showCobrandedLogo) ||
            (overWriteDesign && (
              <OeLogo
                width={64}
                marginInline={'auto'}
                marginBlockEnd={{ base: 8, lg: 16 }}
              />
            ))}
          {showCobrandedLogo && (
            <HStack
              justifyContent={'left'}
              mb={distributor ? 10 : 50}
              pl={'7'}
              bgColor={abTestNewDesign ? 'white' : undefined}
              height={abTestNewDesign ? '100' : undefined}
            >
              <OeLogo width={64} />
              <Text pr={2}>{t('pages:pre_paid_welcome_page.in_collaboration_with')}</Text>
              <img
                src={svgUrl}
                alt={`${distributorName}` + ' Logo'}
                width={136}
                marginInline={'auto'}
                marginBlockEnd={{ base: 8, lg: 16 }}
              />
            </HStack>
          )}
          <Container
            padding="10"
            w={'full'}
            maxW="8xl"
            backgroundColor={backgroundColor ? backgroundColor : ''}
          >
            {showCobrandedSubheadline && (
              <Box textAlign={'center'} mb={70}>
                <Trans
                  i18nKey={`pages:pre_paid_welcome_page.sub_headline`}
                  values={{ distributor: distributorName }}
                />
              </Box>
            )}
            {children}
          </Container>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container padding="10" w={'full'} maxW="8xl">
            {showOELogo && !showCobrandedLogo && (
              <OeLogo
                width={64}
                marginInline={'auto'}
                marginBlockEnd={{ base: 8, lg: 16 }}
              />
            )}
            {showCobrandedLogo && (
              <HStack justifyContent={'center'} mb={distributor ? 10 : 70}>
                <OeLogo width={64} />
                <Text pr={2}>
                  {t('pages:pre_paid_welcome_page.in_collaboration_with')}
                </Text>
                <img
                  src={svgUrl}
                  alt={`${distributorName}` + ' Logo'}
                  width={136}
                  marginInline={'auto'}
                  marginBlockEnd={{ base: 8, lg: 16 }}
                />
              </HStack>
            )}
            {showCobrandedSubheadline && (
              <Box textAlign={'center'} mb={70}>
                <Trans
                  i18nKey={`pages:pre_paid_welcome_page.sub_headline`}
                  values={{ distributor: distributorName }}
                />
              </Box>
            )}
            {children}
          </Container>
        </motion.div>
      )}
    </Box>
  );
};
